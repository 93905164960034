@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #ffffff;
  @apply text-black;
}
.custom-border-radius-5px {
  border-radius: 5px;
}
.swiper {
  width: 700px;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@keyframes scroll{
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-250px * 13));
  }
}
.hidden { 
  display: none; 
} 
.slider{
  height: 150px;
  margin: auto;
  margin-top: 200px;
  overflow: hidden;
  position: relative;
  width: auto;
}
.slider .slide-track{
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 26);
  justify-content: space-between;
}
.slider .slide{
  height: 150px;
  width: 350px;
}
#root {
  --swiper-theme-color: #fff;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;        
  --swiper-pagination-bullet-size: 12px;
}



